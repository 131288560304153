import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  ASK_FOR_OFFER_SUCCESS,
  ASK_FOR_OFFER_FAILURE,
  ASK_FOR_OFFER_REQUEST,
} from './constants'

export const initCartState = {
  items: [],
  errors: undefined,
  loading: false,
}

export const CartReducer = (state = initCartState, action) => {
  const { payload } = action
  switch (action.type) {
    case ADD_TO_CART: {
      const existingItem = state.items.find(
        (item) => item.contentful_id === payload.contentful_id
      )
      if (existingItem) {
        return {
          ...state,
          items: [
            ...state.items.filter(
              (item) => item.contentful_id !== payload.contentful_id
            ),
            { ...existingItem, qty: existingItem.qty + 1 },
          ],
        }
      } else {
        return { ...state, items: [...state.items, { ...payload, qty: 1 }] }
      }
    }
    case REMOVE_FROM_CART: {
      return {
        ...state,
        items: [
          ...state.items.filter(
            (item) => item.contentful_id !== payload.contentful_id
          ),
        ],
      }
    }
    case CLEAR_CART: {
      return {
        ...state,
        items: [],
      }
    }
    case ASK_FOR_OFFER_REQUEST: {
      return { ...state, loading: true }
    }
    case ASK_FOR_OFFER_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case ASK_FOR_OFFER_FAILURE: {
      return { ...state, loading: false, errors: action.payload }
    }
    default:
      return state
  }
}
