import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { load, save } from 'redux-localstorage-simple'
import { createStore, applyMiddleware } from 'redux'
import combinedReducers from './rootReducer'
import { initCartState } from 'modules/cart/state/reducers'
import { initMessagesState } from 'modules/messages/state/reducers'

const middlewares = [thunk, save({ states: ['theme', 'cart'] })]

const init = {
  messages: initMessagesState,
  cart: initCartState,
}

const getLoadedState = (preloadedState) => {
  if (typeof window !== 'undefined')
    return {
      ...preloadedState,
      ...load({
        states: ['theme', 'cart'],
        disableWarnings: true,
      }),
    }

  return {
    ...preloadedState,
  }
}

export default (preloadedState = init) => {
  return createStore(
    combinedReducers,
    getLoadedState(preloadedState),
    composeWithDevTools(applyMiddleware(...middlewares))
  )
}
