import { v4 as uuid } from 'uuid'
import { ADD_MESSAGE, CLEAR_MESSAGES, REMOVE_MESSAGE } from './constants'

export const initMessagesState = {
  data: [],
}

export const MessagesReducer = (state = initMessagesState, action) => {
  const { type, payload } = action

  switch (type) {
    case ADD_MESSAGE:
      return {
        ...state,
        data: [{ ...action.payload.message, id: uuid() }],
      }

    case REMOVE_MESSAGE:
      return {
        ...state,
        data: [
          ...state.data.filter((message) => message.id !== payload.messageId),
        ],
      }
    case CLEAR_MESSAGES:
      return {
        ...state,
        data: [],
      }

    default:
      return state
  }
}
