export const ADD_TO_CART = '@@cart/ADD_TO_CART'
export const ADD_TO_CART_SUCCESS = '@@cart/ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_FAILURE = '@@cart/ADD_TO_CART_FAILURE'
export const REMOVE_FROM_CART = '@@cart/REMOVE_FROM_CART'
export const REMOVE_FROM_CART_SUCCESS = '@@cart/REMOVE_FROM_CART_SUCCESS'
export const REMOVE_FROM_CART_FAILURE = '@@cart/REMOVE_FROM_CART_FAILURE'
export const CLEAR_CART = '@@cart/CLEAR_CART'
export const ASK_FOR_OFFER_REQUEST = '@@cart/ASK_FOR_OFFER_REQUEST'
export const ASK_FOR_OFFER_SUCCESS = '@@cart/ASK_FOR_OFFER_SUCCESS'
export const ASK_FOR_OFFER_FAILURE = '@@cart/ASK_FOR_OFFER_FAILURE'
